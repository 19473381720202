<template>
  <div>
    <h3 class="section-subtitle">{{cardData.product_highlights_title}}</h3>
    <div class="card-container" :class="item.highlight_card_position" v-for="(item, index) in cardData.product_highlights_cards" :key="index">
      <template v-if="item.highlight_card_type === 'split'">
        <div class="card-logo">
          <img :src="item.highlight_card_image_url" alt="Imagen de la tarjeta">
        </div>
        <div class="card-description">{{item.highlight_card_description }}</div>
      </template>
      <template v-else="">
        <div class="card-image-full">
          <img :src="item.highlight_card_image_url" alt="Marcador de posición de imagen de tarjeta">
        </div>
      </template>
    </div>
  </div>
</template>

<script>

export default {
  name: "ProductHighlights",
  props: {
    cardData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="scss" scoped="">
img {
  width: 100%;
}
.card-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.875rem;
  @include bp-medium {
    flex-direction: row;
    &.right {
      flex-direction: row-reverse;
      .card-description {
        margin-right: 1rem;
      }
    }
    &.left {
      .card-description {
        margin-left: 1rem;
      }
    }
  }
}
.card-image {
  border-radius: 1rem;
  margin-bottom: 1rem;
  overflow: hidden;
  position: relative;
  img {
    border-radius: 1rem;
  }
  @include bp-small {
    max-height: 15rem;
  }
  @include bp-medium {
    flex-basis: 30%;
    max-width: 14.313rem;
    margin-bottom: 0;
    border-radius: 1.5rem;
    max-height: unset;
    img {
      position: absolute;
      height: 100%;
      width: unset;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  @include bp-large {
    max-width: 22.688rem;
  }
}
.card-content {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border-radius: 1.5rem;
  padding: 2.625rem 1.5rem;
  @include bp-medium {
    flex: 1;
    padding: 3.188rem 2rem;
  }
  @include bp-large {
    padding: 4.938rem 2rem 3rem;
  }
}
.card-logo {
  img {
    border-radius: 1rem;
    height: 100%;
  }
}
.card-description {
  font-size: 1.125rem;
  line-height: 1.463rem;
  font-weight: 500;
  background: white;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 8rem 2rem;
  margin-top: 1rem;
  @include bp-xsmall {
    padding: 4rem 2rem;
  }
  @include bp-small {
    padding: 8rem 4rem;
  }
  @include bp-mediumsmall {
    font-size: 1.375rem;
    line-height: 1.788rem;
    padding: 8rem 4rem;
  }
  @include bp-medium {
    width: 60%;
    padding: 0 4rem 0 2.5rem;
    text-align: left;
    margin-top: 0;
  }
  @include bp-large {
    font-size: 2rem;
    line-height: 2.6rem;
  }
}
.card-image-full {
  img {
    border-radius: 2rem;
  }
}
.section-subtitle {
  margin: 0 auto 3.5rem;
  max-width: 70%;
  text-align: center;
}
</style>
