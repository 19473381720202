<template>
  <div>
    <h3 class="section-subtitle">{{cardData.product_cards_title}}</h3>
    <div class="card-container" :class="item.card_image_position" v-for="(item, index) in cardData.product_cards_repeater" :key="index">
      <div class="card-image">
        <img :src="item.card_image_url" alt="Marcador de posición de imagen de tarjeta">
      </div>
      <div class="card-content" :class="item.card_content_type">
        <template v-if="item.card_content_type === 'normal'">
          <div class="card-logo">
            <img :src="item.logo_url" alt="Imagen del logotipo de la tarjeta">
          </div>
          <p class="card-description">{{item.card_description }}</p>
        </template>
        <template v-else="">
          <img class="card-quote" :src="QUOTE_ICON" alt="Marcador de posición de imagen de tarjeta">
          <p class="quote-description">{{item.card_description }}</p>
          <div class="card-signature-wrapper">
            <div class="card-signature">
              <p class="signature-name">{{item.quote_title }}</p>
              <p class="signature-department">{{item.quote_description }}</p>
            </div>
            <img class="card-quote-logo" :src="item.logo_url" alt="Logotipo de la cotización de la tarjeta">
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
const QUOTE_ICON = 'https://res.cloudinary.com/healthytogether/image/upload/v1634776260/htio/product-pages/quote-icon.png';

export default {
  name: "ProductCards",
  props: {
    cardData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  created() {
    this.QUOTE_ICON = QUOTE_ICON;
  },
};
</script>

<style lang="scss" scoped="">
img {
  width: 100%;
}
.card-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.875rem;
  @include bp-medium {
    flex-direction: row;
    &.right {
      flex-direction: row-reverse;
      .card-content {
        margin-right: 1rem;
      }
    }
    &.left {
      .card-content {
        margin-left: 1rem;
      }
    }
  }
}
.card-image {
  border-radius: 1rem;
  margin-bottom: 1rem;
  overflow: hidden;
  position: relative;
  img {
    border-radius: 1rem;
  }
  @include bp-small {
    max-height: 15rem;
  }
  @include bp-medium {
    flex-basis: 30%;
    max-width: 14.313rem;
    margin-bottom: 0;
    border-radius: 1.5rem;
    max-height: unset;
    img {
      position: absolute;
      height: 100%;
      width: unset;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  @include bp-large {
    max-width: 22.688rem;
  }
}
.card-content {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border-radius: 1.5rem;
  padding: 2.625rem 1.5rem;
  @include bp-medium {
    flex: 1;
    padding: 3.188rem 2rem;
  }
  @include bp-large {
    padding: 4.938rem 2rem 3rem;
  }
  &.quoted {
    @include bp-mediumsmall {
      padding: 2.5rem 2rem 3rem;
    }
    @include bp-large {
      padding: 5.25rem 2rem 3rem;
    }
  }
}
.card-quote {
  margin-bottom: 1rem;
  max-width: 1.2rem;
  @include bp-large {
    margin-bottom: 2rem;
    max-width: 1.65rem;
  }
}
.card-quote-logo {
  border-radius: 50%;
  max-width: 2.5rem;
  height: 2.5rem;
  @include bp-large {
    max-width: 4.5rem;
    height: 4.5rem;
  }
}
.card-logo {
  max-width: 12rem;
  margin-bottom: 1.688rem;
  img {
    max-height: 6rem;
    width: unset;
    max-width: 12rem;
  }
  @include bp-medium {
    margin-bottom: 2.718rem;
  }
}
.card-signature-wrapper {
  display: flex;
  margin-left: auto;
}
.card-signature {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-right: 2rem;
  p {
    margin-bottom: 0;
  }
}
.card-description {
  font-size: 1.125rem;
  line-height: 1.463rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  @include bp-mediumsmall {
    font-size: 1.375rem;
    line-height: 1.788rem;
  }
  @include bp-medium {
    margin-bottom: 1rem;
  }
  @include bp-large {
    font-size: 2.5rem;
    line-height: 3rem;
  }
}
.quote-description {
  font-weight: 400;
  margin-bottom: 2rem;
  font-size: 0.875rem;
  line-height: 1.225rem;
  @include bp-medium {
    margin-bottom: 1.813rem;
    font-size: 1.125rem;
    line-height: 1.463rem;
  }
  @include bp-large {
    margin-bottom: 3.813rem;
    font-size: 1.375rem;
    line-height: 1.788rem;
  }
}
.signature-name {
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.225rem;
  @include bp-medium {
    font-size: 1.125rem;
    line-height: 1.463rem;
  }
  @include bp-large {
    font-size: 1.375rem;
    line-height: 1.788rem;
  }
}
.signature-department {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.225rem;
  @include bp-medium {
    font-size: 1.125rem;
    line-height: 1.463rem;
  }
  @include bp-large {
    font-size: 1.375rem;
    line-height: 1.788rem;
  }
}
</style>
