<template>
  <div>
    <h3 class="section-subtitle">{{cardData.cta_text}}</h3>
    <btn custom-class="button-link small" :text="cardData.button_text" @btn-click="openContactUs">
    </btn>
  </div>
</template>

<script>
import Btn from '@/components/basic/Btn';

export default {
  name: "ProductCta",
  components: {
    Btn,
  },
  props: {
    cardData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    openContactUs(){
      this.$zendesk.open();
    },
  },
};
</script>

<style lang="scss" scoped="">
img {
  width: 100%;
}
.button-link {
  padding: 1rem;
  max-width: 13.188rem;
  font-size: 0.875rem;
  line-height: 1.225rem;
  font-weight: 400;
  margin-bottom: 1rem;
  @include bp-small {
    padding: 1rem 2.5rem;
    font-size: 1.125rem;
    line-height: 1.463rem;
  }
  @include bp-mediumsmall {
    font-size: 1.125rem;
    line-height: 1.463rem;
  }
  @include bp-medium {
    font-size: 1rem;
    line-height: 1.4rem;
  }
  @include bp-large {
    font-size: 1.375rem;
    line-height: 1.788rem;
    max-width: unset;
  }
}
</style>
